body {
  margin: 0;
  font-family: Roboto, serif;
}

img {
  display: block;
}

h1, h2, h3, h4, p {
  margin-top: 0;
  margin-bottom: 0;
}

ul {
  padding-left: 0;
}

.link {
  text-decoration: none;
}

.visually-hidden {
  width: 1px;
  height: 1px;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.list {
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
}

.line {
  align-items: center;
  gap: 20px;
  display: flex;
}

.header {
  display: none;
}

.page {
  margin: 0;
}

.header-container {
  justify-content: space-between;
  align-items: center;
  padding: 20px 14px;
  display: flex;
}

.logo {
  align-items: center;
  display: flex;
}

.header-icon-focus {
  fill: #000;
  stroke: none;
}

.header-nav-item {
  color: #000;
}

.mobile-menu-open-btn {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  margin: 0;
  padding: 0;
  line-height: 0;
}

.burger-icon {
  stroke: #000;
}

.mobile-menu {
  max-width: 220px;
  max-height: 830px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  background-color: #bfbfd6;
  position: fixed;
  top: 0;
  right: 0;
}

.mobile-menu.is-open {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  z-index: 84;
}

.mobile-menu-container {
  height: 830px;
  flex-direction: column;
  gap: 40px;
  margin-left: auto;
  margin-right: auto;
  padding: 136px 44px 20px 20px;
  display: flex;
  position: relative;
}

.mobile-menu-close-btn {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  position: absolute;
  top: 20px;
  right: 20px;
}

.mobile-menu-close-icon {
  stroke: #000;
}

.mobile-menu-close-icon:hover {
  stroke: #565656;
}

.header-nav-list-mobile {
  color: #fff;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  transition: stroke .25s cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.header-nav-item-mobile:hover {
  color: gray;
}

.social-networks-mobile {
  gap: 18px;
  display: flex;
}

.social-networks-icon-mobile {
  fill: rgba(0, 0, 0, 0);
  stroke: #fff;
  transition: stroke .25s cubic-bezier(.4, 0, .2, 1);
}

.social-networks-icon-mobile:hover, .header-icon-focus:focus {
  stroke: gray;
}

@media screen and (max-width: 1199px) {
  .header-nav, .social-networks {
    display: none;
  }
}

@media screen and (min-width: 375px) {
  .header-container {
    width: 360px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 768px) {
  .header-container {
    width: 768px;
    padding: 24px 32px;
  }

  .mobile-menu-open-btn {
    width: 32px;
    height: 32px;
  }
}

@media screen and (min-width: 1200px) {
  .header-container {
    width: 1200px;
    padding: 24px 32px 32px;
  }

  .header-nav {
    display: flex;
  }

  .header-nav-list {
    letter-spacing: -.02em;
    gap: 24px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
    display: flex;
  }

  .social-networks {
    height: 24px;
    gap: 18px;
    display: flex;
  }

  .social-networks-icon {
    fill: none;
    stroke: #000;
    transition: stroke .25s cubic-bezier(.4, 0, .2, 1);
  }

  .mobile-menu-open-btn {
    display: none;
  }

  .header-nav-item {
    transition: color .25s cubic-bezier(.4, 0, .2, 1);
    position: relative;
  }

  .header-nav-item:hover, .header-nav-item:focus {
    color: gray;
  }

  .nav-item-pseudo:after {
    content: "";
    width: 75%;
    height: 1px;
    background-color: gray;
    border-radius: 2px;
    transition: color .25s cubic-bezier(.4, 0, .2, 1);
    display: none;
    position: absolute;
    bottom: -8px;
  }

  .nav-item-pseudo:hover:after, .nav-item-pseudo:focus:after {
    display: block;
  }

  .social-networks-icon:hover, .social-networks-icon:focus {
    stroke: gray;
  }
}

@media screen and (min-width: 320px) {
  .container {
    width: 294px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 768px) {
  .container {
    width: 730px;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    width: 1114px;
  }
}

@media screen and (min-width: 300px) {
  .logo-decor {
    color: #000;
    text-transform: uppercase;
    letter-spacing: -.03em;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.28;
  }

  .logo-icon {
    width: 12px;
    height: 12px;
  }

  .social-list-item {
    display: none;
  }

  .adress-nav {
    flex-wrap: wrap;
  }

  .footer-contacts-title {
    margin-bottom: 0;
  }

  .footer-adress-nav {
    justify-content: center;
  }

  .footer-social-list {
    justify-content: center;
    margin-top: 20px;
    margin-left: 14px;
  }

  .footer-map {
    width: 290px;
    height: 290px;
    margin-top: 40px;
  }

  .footer-info {
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;
    display: flex;
  }

  .footer-adress-nav {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    display: inline-flex;
  }

  .footer-social-block {
    margin-bottom: 40px;
  }

  .footer-contact-adress-text {
    font-size: 14px;
  }
}

@media screen and (min-width: 768px) {
  .logo-decor {
    color: #000;
    text-transform: uppercase;
    letter-spacing: -.03em;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.28;
  }

  .logo-icon {
    width: 16px;
    height: 16px;
  }

  .social-list-item {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .services-list-item {
    width: 264px;
    min-height: 109px;
    width: calc(50% - 36px);
  }

  .footer {
    height: 400px;
    padding-top: 40px;
  }

  .footer-info {
    flex-direction: row;
    justify-content: space-between;
    gap: 40px;
    display: flex;
  }

  .footer-social-list {
    justify-content: flex-start;
    align-items: center;
    margin-top: 0;
    margin-left: 0;
  }

  .footer-adress-nav {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    margin-top: 20px;
    display: inline-flex;
  }

  .footer-map {
    width: 350px;
    height: 350px;
    margin-top: 0;
  }

  .footer-contact-adress-text {
    font-size: 20gitpx;
  }
}

@media screen and (min-width: 1200px) {
  .services-list-item {
    width: 264px;
    min-height: 109px;
    width: calc(33.3333% - 24px);
  }

  .footer-map {
    width: 500px;
    height: 350px;
    margin-top: 0;
  }
}

.contact-adress, .header-title {
  color: #000;
}

.contact-adress {
  color: #fff;
}

.social-list-icon {
  fill: #fff;
}

.social-list {
  align-items: center;
  gap: 20px;
  padding: 20px;
  display: flex;
}

.adress-nav {
  gap: 20px;
  display: flex;
}

.adress {
  align-items: center;
  display: flex;
}

.services-title {
  letter-spacing: .02em;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 72px;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.11;
}

.services-icon {
  color: var(--primary-text-color);
  height: 112px;
  background-color: #f4f4fd;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  padding: 30px;
  display: flex;
  box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
}

.services-list {
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 72px;
  display: flex;
}

.services-list-item {
  box-sizing: border-box;
  text-align: left;
  margin-bottom: 0;
}

.services-name {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}

.services-text {
  margin-top: 10px;
}

.services-name-list {
  margin-top: 8px;
  margin-left: 20px;
}

.footer {
  background-color: #03070d;
  margin-top: 72px;
}

.footer-contacts-blocks {
  margin-top: 20px;
}

.footer-contacts-block {
  color: #fff;
}

.footer-contacts-title {
  letter-spacing: .02em;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.11;
}

.footer-social-list-icon {
  fill: #fff;
}

.footer-social-list {
  align-items: center;
  gap: 20px;
  margin-top: 16px;
  display: flex;
}

.footer-contact-adress {
  color: #fff;
}

.hero {
  margin-left: auto;
  margin-right: auto;
}

.hero-title {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 30px;
  margin: auto;
}

.call-btn {
  color: #000;
  background: #f4f4fd;
  border: none;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  line-height: 1.21;
  display: flex;
}

.call-btn:hover, .call-btn:focus {
  color: #000;
  background: #fff;
}

.hero-link {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #fff;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.21;
  display: flex;
}

.hero-link:hover, .hero-link:focus {
  color: #4f8ca8;
  border: 1px solid #4f8ca8;
}

@media screen and (max-device-pixel-ratio: 1) and (max-width: 767px), screen and (max-resolution: 96dpi) and (max-width: 767px), screen and (max-resolution: 1x) and (max-width: 767px) {
  .hero-title {
    background-image: linear-gradient(rgba(17, 17, 17, 0) 0%, rgba(17, 17, 17, .4) 100%), url("peakpxm.c3b070f4.jpg");
  }
}

@media screen and (min-device-pixel-ratio: 2) and (max-width: 767px), screen and (min-resolution: 192dpi) and (max-width: 767px), screen and (min-resolution: 2x) and (max-width: 767px) {
  .hero-title {
    background-image: linear-gradient(rgba(17, 17, 17, 0) 0%, rgba(17, 17, 17, .4) 100%), url("peakpxm.c3b070f4.jpg");
  }
}

@media screen and (max-width: 767px) {
  .container {
    max-width: 375px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .hero {
    padding-bottom: 72px;
  }

  .hero-title {
    padding: 395px 20px 20px;
  }

  .hero-text {
    letter-spacing: -.01em;
    color: #fff;
    margin-bottom: 32px;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    display: none;
  }

  .call-btn {
    border-radius: 50%;
    margin-right: 14px;
    padding: 12px;
  }

  .call-btn-text {
    display: none;
  }

  .hero-link {
    padding: 12px 27px;
  }

  .scroll-container {
    flex-wrap: wrap;
    gap: 32px 12px;
    display: flex;
  }

  .hero-scroll-text {
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-block;
  }
}

@media screen and (max-device-pixel-ratio: 1) and (min-width: 768px) and (max-width: 1199px), screen and (max-resolution: 96dpi) and (min-width: 768px) and (max-width: 1199px), screen and (max-resolution: 1x) and (min-width: 768px) and (max-width: 1199px) {
  .hero-title {
    background-image: linear-gradient(rgba(17, 17, 17, 0) 0%, rgba(17, 17, 17, .4) 100%), url("peakpxt.15666a7a.jpg");
  }
}

@media screen and (min-device-pixel-ratio: 2) and (min-width: 768px) and (max-width: 1199px), screen and (min-resolution: 192dpi) and (min-width: 768px) and (max-width: 1199px), screen and (min-resolution: 2x) and (min-width: 768px) and (max-width: 1199px) {
  .hero-title {
    background-image: linear-gradient(rgba(17, 17, 17, 0) 0%, rgba(17, 17, 17, .4) 100%), url("peakpxt.15666a7a.jpg");
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .container {
    width: 768px;
  }

  .hero {
    padding-bottom: 50px;
  }

  .hero-title {
    padding: 670px 40px 40px;
  }

  .scroll-container {
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .container {
    padding-left: 32px;
    padding-right: 32px;
  }

  .hero {
    padding-bottom: 72px;
  }

  .hero-text {
    letter-spacing: -.03em;
    color: #000;
    max-width: 484px;
    margin-bottom: 32px;
    font-size: 28px;
    font-weight: 500;
    line-height: 1.21;
  }

  .call-btn {
    margin-right: 14px;
    padding: 14px 40px;
  }

  .call-btn-icon {
    display: none;
  }

  .hero-link {
    padding: 14px 40px;
  }
}

@media screen and (max-device-pixel-ratio: 1) and (min-width: 1200px), screen and (max-resolution: 96dpi) and (min-width: 1200px), screen and (max-resolution: 1x) and (min-width: 1200px) {
  .hero-title {
    background-image: linear-gradient(rgba(17, 17, 17, 0) 0%, rgba(17, 17, 17, .4) 100%), url("peakpx.a6b5b169.jpg");
  }
}

@media screen and (min-device-pixel-ratio: 2) and (min-width: 1200px), screen and (min-resolution: 192dpi) and (min-width: 1200px), screen and (min-resolution: 2x) and (min-width: 1200px) {
  .hero-title {
    background-image: linear-gradient(rgba(17, 17, 17, 0) 0%, rgba(17, 17, 17, .4) 100%), url("peakpx.a6b5b169.jpg");
  }
}

@media screen and (min-width: 1200px) {
  .container {
    width: 1200px;
  }

  .hero-text {
    padding-bottom: 340px;
  }

  .hero-title {
    padding: 80px 40px 40px;
  }

  .hero-scroll-link {
    margin-left: auto;
  }

  .scroll-container {
    display: flex;
  }
}

/*# sourceMappingURL=index.0e054155.css.map */
