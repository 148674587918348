
body {
    font-family: 'Roboto', serif;
    margin: 0;
}

img {
    display: block;
}

h1,
h2,
h3,
h4,
p {
    margin-top: 0;
    margin-bottom: 0;
}

ul {
    padding-left: 0;
}

.link {
    text-decoration: none;
}
.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
}

.list {
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;
}

.line {
    display: flex;
    align-items: center;
    gap: 20px;
}
.header{
    display: none;
}
.page {
 
    margin: 0;
}
.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 14px;
}
.logo{
    display: flex;
    align-items: center;
}


.header-icon-focus{
    fill:black;
    stroke:none;
}
.header-nav-item{
    color: #000000;
}


/* =============== MOBILE MENU =============== */
.mobile-menu-open-btn {
    border: none;
    margin: 0;
    padding: 0;
    background-color: transparent;
    line-height: 0;
}
.burger-icon{
    stroke: black;
}


.mobile-menu {
    position: fixed;
    max-width: 220px;
    max-height: 830px;
    top: 0;
    right: 0;
    background-color:#bfbfd6;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
}

.mobile-menu.is-open {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    z-index: 84;
}

.mobile-menu-container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 40px;
    height: 830px;
    padding: 136px 44px 20px 20px;
    margin-left: auto;
    margin-right: auto;
}

.mobile-menu-close-btn {
    position: absolute;
    display: flex;
    top: 20px;
    right: 20px;
    padding: 0;
    margin: 0;
    border: none;
    background-color: transparent;
}

.mobile-menu-close-icon {
stroke: #000000;
    
   
}

.mobile-menu-close-icon:hover {
    stroke: rgb(86, 86, 86);
}

.header-nav-list-mobile {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
    color: white;
    transition: stroke 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.header-nav-item-mobile:hover {
    color: grey;
}

.social-networks-mobile {
    display: flex;
    gap: 18px;
}

.social-networks-icon-mobile {
    fill: transparent;
    stroke: white;
    transition: stroke 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.social-networks-icon-mobile:hover {
    stroke: grey;
}

.header-icon-focus:focus {
    stroke: grey;
}

@media screen and (max-width: 1199px) {

    .header-nav,
    .social-networks {
        display: none;
    }
}

@media screen and (min-width: 375px) {
    .header-container {
        margin-left: auto;
        margin-right: auto;
        width: 360px;
    }
}

/* =============== TABLET =============== */

@media screen and (min-width: 768px) {
    .header-container {
        width: 768px;
        padding: 24px 32px;
    }

    .mobile-menu-open-btn {
        width: 32px;
        height: 32px;
    }
}

/* =============== DESCTOP =============== */

@media screen and (min-width: 1200px) {
    .header-container {
        width: 1200px;
        padding: 24px 32px 32px 32px;
    }

    .header-nav {
        display: flex;
    }

    .header-nav-list {
        display: flex;
        gap: 24px;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.14;
        letter-spacing: -0.02em;
    }

    .social-networks {
        display: flex;
        height: 24px;
        gap: 18px;
    }

    .social-networks-icon {
        fill: none;
        stroke: black;
        transition: stroke 250ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    .mobile-menu-open-btn {
        display: none;
    }

    .header-nav-item {
        position: relative;
        transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    .header-nav-item:hover,
    .header-nav-item:focus {
        color: grey;
    }

    .nav-item-pseudo:after {
        position: absolute;
        display: none;
        bottom: -8px;
        content: '';
        width: 75%;
        height: 1px;
        border-radius: 2px;
        background-color: grey;
        transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    .nav-item-pseudo:hover::after,
    .nav-item-pseudo:focus::after {
        display: block;
    }

    .social-networks-icon:hover,
    .social-networks-icon:focus {
        stroke: grey;
    }
}

@media screen and (min-width: 320px) {
    .container {
       width: 294px;
       margin-left: auto;
       margin-right: auto;

    }

}

@media screen and (min-width: 768px) {
    .container {
        width: 730px;
        
    }
}

@media screen and (min-width: 1200px) {
    .container {
        width: 1114px;
    }
}


@media screen and (min-width: 300px) {
    .logo-decor {
            color: black;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 14px;
            line-height: 1.28;
            letter-spacing: -0.03em;
        }
        .logo-icon{
            width: 12px;
            height: 12px;
        }
    
        .social-list-item{
            display: none;
        }
        
        .adress-nav {
            flex-wrap: wrap;
        }
        
        .footer-contacts-title {
            margin-bottom: 0px;
        }

.footer-adress-nav {
      
    justify-content: center;
    
   
}
.footer-social-list {
    margin-top: 20px;
    margin-left: 14px;
   justify-content: center;
}
.footer-map{
    width:290px;
    height: 290px;
    margin-top: 40px;
   
}
.footer-info {
    gap: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}
.footer-adress-nav {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
}
.footer-social-block{
    margin-bottom: 40px;
}

.footer-contact-adress-text {
    font-size: 14px;
}

}

@media screen and (min-width: 768px)  {
    .logo-decor {
            color: black;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 20px;
            line-height: 1.28;
            letter-spacing: -0.03em;
        }
 .logo-icon {
     width: 16px;
     height: 16px;
 }
.social-list-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    /* background-color: rgb(255, 255, 255); */
    border-radius: 50%;
}


.services-list-item {
    width: 264px;
    min-height: 109px;
    width: calc((100% - 72px) / 2);
    }

.footer {
    height: 400px;
  padding-top: 40px;
    
}
.footer-info{
    gap: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
}
.footer-social-list {
    margin-top: 0px;
    margin-left: 0px;
    justify-content: flex-start;
    align-items: center;
}
.footer-adress-nav {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    margin-top: 20px;


}
.footer-map {
    width: 350px;
    height: 350px;
    margin-top: 0;

}
.footer-contact-adress-text {
    font-size: 20gitpx;
}
}

@media screen and (min-width: 1200px) {


   .services-list-item {
       width: 264px;
       min-height: 109px;

       width: calc((100% - 72px) / 3);
   }
.footer-map {
    width: 500px;
    height: 350px;
    margin-top: 0;
}

}


.contact-adress{
    color: rgb(0, 0, 0);
}


.header-title{
    color: black;
}

.contact-adress {
    color: white;
}
.social-list-icon{
    fill: white;
}

.social-list{
        padding: 20px;
        display:flex;
        gap: 20px;
      align-items: center;
      
    }

.adress-nav{
    display: flex;
    gap: 20px;
    }

.adress{
        display: flex;
        align-items: center;
        
    }

.services-title{
    /* padding: 90px 0; */
    font-weight: 700;
    font-size: 36px;
    line-height: 1.11;
    letter-spacing: 0.02em;
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 72px;

}

.services-icon{
    background-color: #F4F4FD;

    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    color: var(--primary-text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 112px;
    padding: 30px;
    margin-bottom: 16px;
    
}
.services-list{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 72px;
    justify-content: center;
}

.services-list-item{
    box-sizing: border-box;
    text-align: left;
  
    margin-bottom: 0;
  
}
.services-name {
    font-size: 20px;
    line-height: 1.2;
    font-weight: 600;
    text-align: center;
}
.services-text{
    margin-top: 10px;
}
.services-name-list{
    margin-top: 8px;
    margin-left: 20px;
}


.footer{
    margin-top:  72px;
background-color: #03070d;
}

.footer-contacts-blocks{
    /* display: flex;
    justify-content: space-around; */
    margin-top: 20px;
}
.footer-contacts-block{
 
    color: white;
}
.footer-contacts-title{
    font-weight: 500;
    font-size: 24px;
    line-height: 1.11;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    /* padding-top: 40px; */
  
    text-align: center;
    color: white;
}
.footer-social-list-icon {
    fill: white;
}

.footer-social-list {
    display: flex;
    gap: 20px;
    margin-top: 16px;
    align-items: center;
}
.footer-contact-adress{
    color: white;
}



/* .footer-adress {
    display: flex;
    align-items: center;} */


        .hero {
            margin-left: auto;
            margin-right: auto;
            /* margin-bottom: 71px; */
        }
    
        .hero-title {
            margin: auto;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 30px;
            background-size: cover;

        }
    
        .call-btn {
            display: flex;
            border: none;
    
            font-weight: 700;
            line-height: 1.21;
            color:black;
            align-items: center;
            justify-content: center;
            background:#F4F4FD;
            border-radius: 100px;
        }
    
        .call-btn:hover,
        .call-btn:focus {
            background: white;
            color: black;
        }
    
        .hero-link {
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            background-color: transparent;
            border: 1px solid white;
            border-radius: 100px;
            font-weight: 600;
            font-size: 14px;
            line-height: 1.21;
        }
    
        .hero-link:hover,
        .hero-link:focus {
            border: 1px solid rgb(79, 140, 168);
            color: rgb(79, 140, 168);
        }
    
        
       
    
        /* --------------------------------------------------------------------------------- */
    
        /* Mobile hero image 1x */
        @media screen and (max-device-pixel-ratio: 1) and (max-width: 767px),
        screen and (max-resolution: 96dpi) and (max-width: 767px),
        screen and (max-resolution: 1dppx) and (max-width: 767px) {
            .hero-title {
                background-image: linear-gradient(180deg,
                        rgba(17, 17, 17, 0) 0%,
                        rgba(17, 17, 17, 0.4) 100%),
                            url('./images/peakpxm.jpg');
            }
        }
    
        /* Mobile hero image 2x */
        @media screen and (min-device-pixel-ratio: 2) and (max-width: 767px),
        screen and (min-resolution: 192dpi) and (max-width: 767px),
        screen and (min-resolution: 2dppx) and (max-width: 767px) {
            .hero-title {
                background-image: linear-gradient(180deg,
                        rgba(17, 17, 17, 0) 0%,
                        rgba(17, 17, 17, 0.4) 100%),
                            url('./images/peakpxm.jpg');
                    
            }
        }
    
        /*Тільки мобілка*/
    
        @media screen and (max-width: 767px) {
            .container {
                max-width: 375px;
                padding-left: 20px;
                padding-right: 20px;
            }
    
            .hero {
                padding-bottom: 72px;
            }
    
            .hero-title {
                padding: 395px 20px 20px 20px;
            }
    
            .hero-text {
                font-weight: 500;
                font-size: 20px;
                line-height: 1.2;
                letter-spacing: -0.01em;
                margin-bottom: 32px;
                color: white;
                display: none;
            }
    
           
            .call-btn {
                padding: 12px 12px;
                margin-right: 14px;
                border-radius: 50%;
            }

        .call-btn-text{
            display:none;
        }
            .hero-link {
                padding: 12px 27px;
            }
    
    
            .scroll-container {
                display: flex;
    
                column-gap: 12px;
                flex-wrap: wrap;
                row-gap: 32px;
            }
    
            .hero-scroll-text {
                display: inline-block;
                padding-top: 10px;
                padding-bottom: 10px;
            }
    
            
        }
    
        /* ----------------------------------------------------------------------------------------------------------------- */
    
        /*Мобілка і планшет*/
        @media screen and (max-width: 1199px) {}
    
        /* --------------------------------------------------------------------------------------------------------- */
    
        @media screen and (max-device-pixel-ratio: 1) and (min-width: 768px) and (max-width: 1199px),
        screen and (max-resolution: 96dpi) and (min-width: 768px) and (max-width: 1199px),
        screen and (max-resolution: 1dppx) and (min-width: 768px) and (max-width: 1199px) {
            .hero-title {
                background-image: linear-gradient(180deg,
                        rgba(17, 17, 17, 0) 0%,
                        rgba(17, 17, 17, 0.4) 100%),
                            url('./images/peakpxt.jpg');
                    
            }
        }
    
        /* Laptop image 2px */
        @media screen and (min-device-pixel-ratio: 2) and (min-width: 768px) and (max-width: 1199px),
        screen and (min-resolution: 192dpi) and (min-width: 768px) and (max-width: 1199px),
        screen and (min-resolution: 2dppx) and (min-width: 768px) and (max-width: 1199px) {
            .hero-title {
                background-image: linear-gradient(180deg,
                        rgba(17, 17, 17, 0) 0%,
                        rgba(17, 17, 17, 0.4) 100%),
                            url('./images/peakpxt.jpg');
                    
            }
        }
    
        /*Тільки планшет*/
        @media screen and (min-width: 768px) and (max-width: 1199px) {
            .container {
                width: 768px;
            }
    
            .hero {
                padding-bottom: 50px;
            }
    
            .hero-title {
                padding: 670px 40px 40px 40px;
            }
    
    
            
    
    
            .scroll-container {
                display: flex;
    
            }
        }
    
        /* ------------------------------------------------------------------------------------------ */
    
        /*Планшет і десктоп*/
        @media screen and (min-width: 768px) {
            .container {
                padding-left: 32px;
                padding-right: 32px;
            }
    
            .hero {
                padding-bottom: 72px;
            }
    
            .hero-text {
                font-weight: 500;
                font-size: 28px;
                line-height: 1.21;
                letter-spacing: -0.03em;
                color: black;
                margin-bottom: 32px;
                max-width: 484px;
            }
    
           
    
            .call-btn {
                padding: 14px 40px;
                margin-right: 14px;
            }
    
        .call-btn-icon{
            display: none;
        }
            .hero-link {
                padding: 14px 40px;
    
            }
    
        }
    
        /* ------------------------------------------------------------------------------------------ */
    
        @media screen and (max-device-pixel-ratio: 1) and (min-width: 1200px),
        screen and (max-resolution: 96dpi) and (min-width: 1200px),
        screen and (max-resolution: 1dppx) and (min-width: 1200px) {
            .hero-title {
                background-image: linear-gradient(180deg,
                        rgba(17, 17, 17, 0) 0%,
                        rgba(17, 17, 17, 0.4) 100%),
                        url('./images/peakpx.jpg');
                   
            }
        }
    
        /* Desktop contacts image 2px */
        @media screen and (min-device-pixel-ratio: 2) and (min-width: 1200px),
        screen and (min-resolution: 192dpi) and (min-width: 1200px),
        screen and (min-resolution: 2dppx) and (min-width: 1200px) {
            .hero-title {
                background-image: linear-gradient(180deg,
                        rgba(17, 17, 17, 0) 0%,
                        rgba(17, 17, 17, 0.4) 100%),
                        url('./images/peakpx.jpg');
                   
            }
        }
    
        /*Тільки десктоп*/
        @media screen and (min-width: 1200px) {
            .container {
                width: 1200px;
            }
           .hero-text{
            padding-bottom: 340px;
           }
            .hero-title {
                padding: 80px 40px 40px 40px;
            }
    
            .hero-scroll-link {
                margin-left: auto;
            }
    
            .scroll-container {
                display: flex;
            }
        }